import React, { useState } from 'react';
import { loginMutation, createCustomerMutation } from '../../mutations';
import { useShopifyAPI } from '../../hooks';
import { navigate } from '@reach/router';
import { AppContext } from '../../apollo/wrap-root-element';
import SuccessImg from '../../images/svg-gcbooks/success';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as yup from 'yup';
import { HikoLogin } from '../HikoLogin';

const validationSchemaForLogin = yup.object({
  user: yup.string().required('Name or Email is required'),
  password: yup.string().required('Password is required'),
});

const validationSchemaForSignUp = yup.object({
  user: yup
    .string()
    .min(3, 'Name should contain at least 3 letters')
    .max(255, 'Characters length should not exceed 255')
    .required('Name is required'),
  email: yup.string().email('Email is invalid').required('Email is required'),
  password: yup.string().min(5, 'Password should contain at least 5 characters').required('Password is required'),
});

export default function LoginSignup({ todo }) {
  const loginSignupData = {
    signinHeader: 'Login to your account',
    signinInfo:
      'If you have shopped with us before, please enter your details below. If you are a new customer, please proceed to the Billing & Shipping section.',
    signupHeader: 'Create an account',
    signinFooter: `Don't have an account yet?`,
    signupfooter: 'Already have account?',
    signinLink: ' Join Lineage',
    signupLink: ' Sign In',
    signin: 'LOGIN',
    signup: 'SIGN UP',
    signinuser: 'Username or email*',
    signupUser: 'Full Name*',
    signinPassword: 'Password*',
    signupPassword: 'Password*',
    signupName: ' Full Name*',
    signinurl: '/login',
    signupurl: '/signup',
  };
  const { setCustomerTokenDetails } = React.useContext(AppContext);

  const [login, loginData, loginError, loginLoading] = useShopifyAPI();
  const [register, registerData, registerError, registerLoading] = useShopifyAPI();
  const [successRegister, setSuccessRegister] = React.useState(false);

  //form validation

  const setTokens = (response, rememberMe) => {
    const accessToken = response.data.customerAccessTokenCreate.customerAccessToken.accessToken;
    const expiresAt = response.data.customerAccessTokenCreate.customerAccessToken.expiresAt;
    setCustomerTokenDetails({ accessToken, expiresAt });
    if (rememberMe) {
      localStorage.setItem('shopifyAccessToken', accessToken);
      localStorage.setItem('shopifyAccessTokenExpiresAt', expiresAt);
    }
    // navigate(-1);
    navigate('/');
  };

  // Hiko Login
  const handleHikoLogin = (accessToken) => {
    // Use the access token to log in the user
    setCustomerTokenDetails({ accessToken, expiresAt: null }); // We don't have expiresAt from Hiko, so set it to null
    localStorage.setItem('shopifyAccessToken', accessToken);
    // We don't have expiresAt, so we won't set it in localStorage

    toast.success('Logged in successfully', {
      hideProgressBar: true,
      position: 'bottom-center',
    });
    navigate('/');
  };

  //sign in
  const handleSignIn = ({ user, password, rememberMe }) => {
    login(loginMutation(user, password), {
      onSuccess: (data) => {
        console.log(data, 'data');
        if (data.data.customerAccessTokenCreate.customerUserErrors.length !== 0) {
          toast.error('User does not exists', {
            hideProgressBar: true,
            position: 'bottom-center',
          });
        } else {
          toast.success('Sign In Successful', {
            hideProgressBar: true,
            position: 'bottom-center',
          });
          setTokens(data, rememberMe);
        }
      },
    });
  };

  //sign up
  const handleSignUp = ({ email, password, user }) => {
    const userFullName = user.split(' ');
    register(createCustomerMutation(email, password, userFullName[0]), {
      onSuccess: (data) => {
        if (data?.data?.customerCreate?.customerUserErrors?.length !== 0) {
          toast.error('Email is already registered', {
            hideProgressBar: true,
            position: 'bottom-center',
          });
          return;
        } else {
          // navigate('/');
          setSuccessRegister(true);
          toast.success('Sign Up Successful', {
            hideProgressBar: true,
            position: 'bottom-center',
          });
        }
      },
    });
  };

  return (
    <div className="loginDetails">
      <div className="loginSignUpInputs">
        {successRegister && (
          <div className="success-img">
            <SuccessImg />
          </div>
        )}
        <h4 className="loginSignUpHeader">
          {successRegister
            ? 'Successful'
            : todo === 'signin'
            ? loginSignupData.signinHeader
            : loginSignupData.signupHeader}
        </h4>

        {successRegister ? (
          <>
            <p className="success-msg">
              Your account has been setup successfully.
              <br />
              Click Below to go Login page
            </p>
            <button
              className="btn loader-container btn-md btn-primary-ii btn-submit"
              type="button"
              onClick={() => navigate('/')}
            >
              Go to Login Page
            </button>
          </>
        ) : (
          <>
            <Formik
              validationSchema={todo === 'signup' ? validationSchemaForSignUp : validationSchemaForLogin}
              initialValues={{
                user: '',
                password: '',
                email: '',
                rememberMe: false,
              }}
              onSubmit={(values) => {
                if (todo === 'signin') {
                  handleSignIn({
                    password: values.password,
                    user: values.user,
                    rememberMe: values.rememberMe,
                  });
                } else {
                  handleSignUp({
                    email: values.email,
                    password: values.password,
                    user: values.user,
                  });
                }
              }}
            >
              {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  {console.log(errors, touched, 'asdasd')}
                  <p className="loginSignUpInfo">{todo === 'signin' ? loginSignupData.signinInfo : ''}</p>
                  <div className="loginSignUpForm">
                    <label>{todo === 'signin' ? loginSignupData.signinuser : loginSignupData.signupUser}</label>
                    <input
                      name="user"
                      type="text"
                      placeholder="Name"
                      required
                      value={values.user}
                      onChange={handleChange}
                    ></input>
                    <div className="errorMessage">{touched.user && errors.user ? errors.user : ''}</div>
                    {todo === 'signup' ? (
                      <>
                        <label>Email*</label>
                        <input
                          name="email"
                          type="email"
                          placeholder="Email"
                          required
                          value={values?.email}
                          onChange={handleChange}
                        ></input>
                        <div className="errorMessage">{touched.email && errors.email ? errors.email : ''}</div>
                      </>
                    ) : (
                      ''
                    )}
                    <label>Password*</label>
                    <input
                      name="password"
                      type="password"
                      placeholder="Password"
                      required
                      value={values?.password}
                      onChange={handleChange}
                    ></input>
                    <div className="errorMessage">{touched.password && errors.password ? errors.password : ''}</div>
                    <div className="form-element submit">
                      {loginLoading || registerLoading ? (
                        <div className="btn loader-container btn-md btn-primary-ii btn-submit">
                          <div class="loader-auth"></div>
                        </div>
                      ) : (
                        <input
                          type="submit"
                          value={todo === 'signin' ? loginSignupData.signin : loginSignupData.signup}
                          className="btn btn-md btn-primary-ii btn-submit"
                        />
                      )}
                    </div>

                    {todo === 'signin' ? (
                      <div className="rememberReset">
                        <div className="form-element remember">
                          <label className="custom-checkbox">
                            Remember me
                            <input
                              type="checkbox"
                              id="rememberCheck"
                              checked={values?.rememberMe}
                              value={values?.rememberMe}
                              onChange={() => {
                                setFieldValue('rememberMe', !values.rememberMe);
                              }}
                            />
                            <span className="check-mark"></span>
                          </label>
                        </div>
                        <div className="form-element forgot  floatRight">
                          <label>
                            <a href="/forgot-password" className="btn-reset">
                              Forgot password?
                            </a>
                          </label>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {/* Hiko Login */}
                    <HikoLogin
                      shop={process.env.GATSBY_SHOPIFY_STORE_URL}
                      publicAccessToken={process.env.GATSBY_SHOPIFY_STORE_FRONT_ACCESS_TOKEN}
                      onSuccess={handleHikoLogin}
                    />

                    <div className="register">
                      {todo === 'signin' ? loginSignupData.signinFooter : loginSignupData.signupfooter}
                      <a
                        href={todo === 'signin' ? loginSignupData.signupurl : loginSignupData.signinurl}
                        className="btn-register"
                      >
                        {todo === 'signin' ? loginSignupData.signinLink : loginSignupData.signupLink}
                      </a>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </>
        )}
      </div>
    </div>
  );
}
