import React, { useState, useEffect, useCallback, useRef, ReactElement } from 'react';
import { SocialLoginWidget } from './SocialLoginWidget';
import './hiko.css';

export function HikoLogin({
  shop,
  publicAccessToken,
  baseUrl = 'https://apps.hiko.link',
  onSuccess,
}: {
  shop: string;
  publicAccessToken: string;
  baseUrl: string;
  onSuccess: (accessToken: string) => void;
}) {
  const logoutCallbackRef = useRef<(() => void) | null>(null);
  const refreshCallbackRef = useRef<(() => void) | null>(null);
  const [customer, setCustomer] = useState(typeof window !== 'undefined' ? window.HIKO?.customer : null);
  const handleCustomEvents = useCallback(
    (event: any) => {
      console.info(`catch event: ${JSON.stringify(event.detail, null, '  ')}`);

      if (['login', 'activate', 'multipass'].includes(event.detail.action)) {
        setCustomer(event.detail.customer);
        // Call onSuccess with the customer's access token
        if (event.detail.customer && event.detail.customer.accessToken) {
          onSuccess(event.detail.customer.accessToken);
        }
      } else if (event.detail.action !== 'click') console.error(`unhandled action ${event.detail.action}`);
    },
    [onSuccess]
  );

  const getCustomerDetail = useCallback(() => {
    fetch(`https://${shop}/api/2023-04/graphql.json`, {
      headers: {
        'Content-Type': 'application/graphql',
        'X-Shopify-Storefront-Access-Token': publicAccessToken,
      },
      method: 'POST',
      body: `query {
                customer(customerAccessToken: "${customer.accessToken}") {
                    id
                    displayName
                    firstName
                    lastName
                    acceptsMarketing
                    phone
                    email
                    createdAt
                    updatedAt
                }
            }`,
    })
      .then((response) => response.json())
      .then((res) => {
        const value = {
          ...res.data.customer,
          ...customer,
        };
        setCustomer(value);
        if (typeof window !== 'undefined') {
          window.HIKO.customer = value;
        }
      })
      .catch((err) => console.error(err.message));
  }, [customer]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.addEventListener('hiko', handleCustomEvents);
      return () => document.removeEventListener('hiko', handleCustomEvents);
    }
  }, [handleCustomEvents]);

  const logout = (callback: () => void) => {
    logoutCallbackRef.current = callback;
  };

  const refresh = (callback: () => void) => {
    refreshCallbackRef.current = callback;
  };

  return (
    <div className="main">
      <div className="widget">
        <SocialLoginWidget
          shop={shop}
          logout={logout}
          refresh={refresh}
          baseUrl={baseUrl}
          publicAccessToken={publicAccessToken}
        />

        {/* <ShowCustomer customer={customer} /> */}
      </div>

      {/* <div className="panel">
        <button
          onClick={() => {
            if (refreshCallbackRef.current) refreshCallbackRef.current();
          }}
        >
          Refresh
        </button>

        {customer ? (
          <>
            <button
              onClick={() => {
                if (logoutCallbackRef.current) {
                  logoutCallbackRef.current();
                  setCustomer(null);
                }
              }}
            >
              Logout
            </button>

            <button onClick={() => getCustomerDetail()}>Customer detail</button>
          </>
        ) : null}
      </div> */}
    </div>
  );
}

const ShowCustomer = ({ customer }: { customer: any }): any => {
  if (!customer) return null;
  return (
    <ul>
      {Object.keys(customer).map((key) => (
        <li key={key}>
          {key}: {customer[key]}
        </li>
      ))}
    </ul>
  );
};
