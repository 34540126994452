export const createCustomerMutation = (email, password, firstName) => `mutation customerCreate {
  customerCreate(input: {
                        email:"${email}",
                        password:"${password}",
                        firstName:"${firstName}",
                       
                    }) {
    customer {
      id
      firstName
      lastName
      acceptsMarketing
      email
      phone
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`;
