export const loginMutation = (email, password) => `
                mutation customerAccessTokenCreate {
                    customerAccessTokenCreate(input: {
                        email:"${email}",
                        password:"${password}"
                    }) {
                      customerAccessToken {
                        accessToken
                        expiresAt
                      }
                      customerUserErrors {
                        code
                        field
                        message
                      }
                    }
                  }  
        `;
