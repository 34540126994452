import React, { useState, useRef, useEffect } from 'react';

const DEFAULT_PATH = '/js/hiko-auth-headless.js';

declare global {
  interface Window {
    HIKO: any;
  }
}

interface SocialLoginWidgetProps {
  shop: string;
  publicAccessToken: string;
  logout: (callback: () => void) => void;
  refresh: (callback: () => void) => void;
  baseUrl: string;
}

export const SocialLoginWidget: React.FC<SocialLoginWidgetProps> = ({
  shop,
  publicAccessToken,
  logout,
  refresh,
  baseUrl,
}) => {
  const container = useRef<HTMLInputElement>(null);
  const [path, setPath] = useState(DEFAULT_PATH);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (!isClient) return;

    if (!document.querySelector(`script[src*="${path}"]`)) {
      const script = document.createElement('script');
      script.src = `${baseUrl}${path}`;
      script.async = true;
      script.onload = () => window.HIKO.render(container.current, shop, publicAccessToken);
      document.head.appendChild(script);
    }

    logout(() => {
      window.HIKO.logout();
      window.HIKO.render(container.current, shop, publicAccessToken);
    });

    refresh(() => {
      const found = document.querySelector(`script[src*="${path}"]`);
      if (found) {
        found.remove();
        window.HIKO.release();
        setPath(`${DEFAULT_PATH}?t=${Date.now()}`);
      }
    });
  }, [isClient, path, shop, publicAccessToken, logout, refresh, baseUrl]);

  if (!isClient) return null;

  return <div ref={container}></div>;
};
